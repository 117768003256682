.app__about {
  width: 100vw;
  height: auto;

  background-color: var(--cl-white);

  .abt-text {
    margin-top: 12vw;

    font-size: 6.5vw;
    font-weight: 600;
    line-height: 100%;
    text-align: justify;

    z-index: 5;
  }
  .phone-abt-text {
    margin-top: 12vw;

    font-size: 6.5vw;
    font-weight: 600;
    line-height: 100%;
    text-align: left;

    z-index: 5;
  }

  .img-me {
    display: none;
    border-radius: 0.5rem;
    width: 25vw;
    // height: 40vw;
    object-fit: contain;

    z-index: 0;
    margin-top: -8vw;

    position: fixed;
    top: 50%; /* Adjust this value based on your design */
    transform: translateY(-50%);
    transition: margin-left 0.5s ease; /* Adjust the transition duration */
  }

  .img-me-backup {
    display: inline;
    width: 25vw;
    height: 40vw;
    object-fit: contain;

    z-index: 0;
    margin-top: -8vw;
    margin-right: 15vw;
    position: static;

    top: 50%; /* Adjust this value based on your design */
    transform: translateY(-50%);
    transition: margin-left 0.5s ease; /* Adjust the transition duration */
  }

  .about-p {
    font-size: 1.75vw;
    margin-left: 28vw;
    margin-top: 1.5vw;

    max-width: 40vw;
    margin-right: 3vw;
  }
  .phone-about-p {
    font-size: 5vw;
    margin-left: 1vw;
    margin-top: 1.5vw;
    margin-right: 1vw;
    margin-bottom: 3vh;
  }

  .abt-p-container {
    display: flex;
    flex-direction: row;

    iframe {
      margin-top: -3.5vw;
      margin-bottom: 3.5vw;
    }
  }

  .spotify-button {
    text-align: center;
    font-size: 5vw;
    font-weight: 400;
    line-height: normal;
    border: 1px solid var(--cl-black);
    border-radius: 3rem;
    padding: 0 3rem;

    height: 3.5rem;
    text-decoration: none;
    color: var(--cl-black);

    display: flex;
    justify-content: center;
    align-items: center;

    margin: 1vw;
    margin-top: 20vh;

    &:hover {
      background-color: var(--cl-nuclear-green);
      border: 1px solid var(--cl-nuclear-green);
      color: var(--cl-black);
      cursor: pointer;
    }
  }

  @media screen and (min-height: 850px) and (max-height: 1100px) {
    .img-me {
      display: block;
    }

    .img-me-backup {
      display: none;
    }

    .phone-abt-text {
      margin-left: 2vw;
      margin-right: 2vw;

      font-size: 10vw;
      text-align: justify;
    }
  }
}
