.PhoneMenu {
  display: none; // Hidden by default

  &.open {
    display: block; // Visible when open
  }
}

.content-overlay {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5em;
  background-color: rgba($color: #000000, $alpha: 0.85);
  color: var(--cl-white);
  z-index: 2;

  .nav-link {
    display: flex;

    span {
      position: relative;
      top: 1em;
      color: var(--cl-nuclear-green);
      font-size: 4vw;
    }

    a {
      padding: 0 0.2em;
      font-size: 17vw;
      text-decoration: none;
      color: var(--cl-white);
    }
  }
}
