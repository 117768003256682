.app__home {
  width: 100vw;
  height: 100vh;

  background-color: var(--cl-white);

  display: flex;
  align-items: flex-end;
}

.app__header {
  width: 100vw;
  max-width: 100vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column; /* Stack items vertically on small screens */
  align-items: center; /* Center items horizontally on small screens */
  padding-left: 0.25rem;
  padding-right: 0.25rem;

  div {
    width: 100vw;
    display: flex;
    justify-content: space-between;
    padding-left: 1vw;
    padding-right: 1vw;
  }

  h1 {
    // font-family: var(--ff-display);
    font-style: normal;
    font-weight: 600;
    font-size: 10.85vw;
    line-height: 1;
    // text-align: center;
    max-width: 100%;
  }

  h2 {
    font-family: var(--ff-display);
    font-style: normal;
    font-weight: 500;
    font-size: 3vw;
    line-height: 0.5;
  }
}

.phone-gif {
  width: 250px;
  height: 125px;
  mix-blend-mode: difference;
  margin-left: -65vw;
}

.phone-intro {
  font-size: 6.5vw;
  margin: 1vw;
  margin-bottom: 3vh;
}

.phone-h1 {
  text-align: left;
  margin-left: 5.5vw;
}

.phone-landing {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.phone-landing-img {
  width: 90vw;
  margin-bottom: 2vh;
  border-radius: 0.5rem;
}
