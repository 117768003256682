.app__project01 {
  width: 100vw;
  height: auto;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;

  .h4 {
    text-transform: uppercase;
    margin-top: 12vw;
    font-size: 7.5vw;
    margin-left: 1vw;
  }

  .proj-thumbnail {
    width: 98vw;
    margin-left: 1vw;
    height: 40vw;
    object-fit: cover;
    border-radius: 1vw;

    @media only screen and (max-width: 500px) {
      height: 25vh;
    }
  }

  .proj-text {
    margin-left: 1vw;
    margin-top: 1vw;
    font-size: 1.75vw;
    width: 48vw;
  }

  .phone-proj-text {
    margin-left: 1vw;
    margin-top: 1vw;
    font-size: 5vw;
    width: 98vw;
  }

  .showcase-container {
    display: flex;
    flex-direction: row;
    margin-left: 1vw;
    margin-top: 5vw;

    @media only screen and (max-width: 500px) {
      flex-direction: column;
    }
  }

  .proj-media:first-child {
    margin-right: 1vw;
  }

  .proj-media {
    width: 48.5vw;
    height: 25vw;
    object-fit: cover;
    border-radius: 0.5vw;

    @media only screen and (max-width: 500px) {
      width: 98vw;
      height: 25vh;
      padding-bottom: 1vh;
    }
  }

  .last-text-box {
    display: flex;
    flex-direction: row;
  }

  .proj-links {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 1vw;
    margin-left: 1vw;

    a {
      font-size: 3vw;
      font-weight: bold;
      text-transform: uppercase;
      color: var(--cl-black);
      text-decoration: none;
      transition: 0.25s ease-in-out;

      &:hover {
        color: var(--cl-nuclear-green);
        text-decoration: underline;
        cursor: pointer;
      }

      @media only screen and (max-width: 500px) {
        font-size: 8vw;
      }
    }
  }

  hr {
    margin: 1vw;
    margin-top: 5vw;

    @media only screen and (max-width: 500px) {
      display: none;
    }
  }

  .proj-footer {
    width: 100vw;
    display: flex;
    flex-direction: row;

    @media only screen and (max-width: 500px) {
      display: none;
    }
  }

  .go-back-btn {
    font-size: 1.25vw;
    margin: 1vw;
    color: var(--cl-black);
    text-decoration: none;
    border: 1px solid var(--cl-black);
    border-radius: 25px;
    padding: 10px;
    padding-left: 30px;
    padding-right: 40px;
    transition: 0.25s ease-in-out;

    &:hover {
      background-color: var(--cl-nuclear-green);
      cursor: pointer;
    }
  }

  .proj-footer-text {
    color: var(--cl-black);
    margin: 1vw;
    font-size: 1.5vw;
  }
}

.phone-proj-header {
  padding-top: 15vh;
  display: flex;
}

.phone-proj-media {
  display: flex;
  flex-direction: column;
}
