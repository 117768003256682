.banner {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--cl-nuclear-green);
  height: 3vh;
  width: 100vw;

  position: fixed;

  p {
    font-weight: 500;
    color: var(--cl-black);
  }
}
