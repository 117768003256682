.app__navbar {
  margin-top: 3vh;
  width: 100%;

  display: flex;
  justify-content: space-between;

  padding: 0.25rem;
  position: fixed;
  z-index: 100;
  mix-blend-mode: difference;
}

.app__navbar-logo {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 25%;

  a {
    text-decoration: none;
    color: var(--cl-white);

    h1 {
      font-family: var(--ff-display);
      font-style: normal;
      font-weight: 600;
      color: var(--cl-white);
    }
  }
}

.app__navbar-text-1 {
  width: 33%;
  color: var(--cl-white);
}

.helmet-gif {
  // width: 100px;
  // height: 50px;
  width: 7vw;
  height: 3.5vw;
  mix-blend-mode: difference;
  margin: -1.2vw;
}

.helmet-gif-mobile {
  width: 30vw;
  height: 15vw;
  margin-left: -1rem;
}

.app__navbar-text-2 {
  margin-left: 2rem;
  width: 25%;
  color: var(--cl-white);
}

.app__navbar-links {
  display: flex;
  flex-direction: column;

  list-style: none;

  margin-right: 0.5rem;
}

.app__navbar-link {
  font-size: 1.4rem;
  text-align: right;
  line-height: 1.4;
  cursor: pointer;

  @media screen and (min-width: 2000px) {
    font-size: 1.75rem;
  }

  a {
    text-transform: capitalize;
    text-decoration: none;
    color: var(--cl-white);

    transition: all 0.2s ease-in-out;

    &:hover {
      text-decoration: none;
      color: var(--cl-nuclear-green);
      mix-blend-mode: normal;
      text-decoration: line-through;
      cursor: pointer;
    }
  }
}

.hamburger-menu {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-top: 2vw;
  margin-right: 2vw;

  .bar {
    width: 35px;
    height: 3px;
    background-color: var(--cl-white);
    margin: 3px 0;
    transition: 0.4s;
  }

  &.open {
    .bar:nth-child(1) {
      transform: rotate(-45deg) translate(0px, 6px);
    }

    .bar:nth-child(2) {
      transform: rotate(45deg) translate(0px, -6px);
    }
  }
}

.scroll-to-top {
  text-decoration: none;
  mix-blend-mode: difference;
  color: var(--cl-white);
  opacity: 0; /* Initially set opacity to 0 */
  transition: opacity 0.3s ease-in-out; /* Add a transition effect for opacity */
}

.scroll-to-top.visible {
  opacity: 0.33; /* Set opacity to 1 when the button is visible */
}

.scroll-to-top.hidden {
  opacity: 0; /* Set opacity to 0 when the button is hidden */
}

.scroll-to-top:hover {
  mix-blend-mode: normal;
  color: var(--cl-nuclear-green);
  opacity: 1;
}
