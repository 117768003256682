.preloader {
  width: 100vw;
  height: 100vh;

  background: var(--cl-black);
  color: var(--cl-white);

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.preloader-gif {
  width: 150px;
  height: 75px;

  /* Animation */
  animation: fadeOut 1s ease-in-out 3.5s forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.preloader .preloader-text-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 10vh;
  width: 33vw;
  font-size: 1.25vw;
  font-weight: 600;
  overflow: hidden;
  text-transform: uppercase;

  animation: fadeOut 1s ease-in-out 3.5s forwards;
}
