@import url(https://use.typekit.net/kyt7rkc.css);

:root {
  --ff-display: neue-haas-grotesk-display, neue-haas-unica, Helvetica,
    sans-serif;
  --ff-text: "neue-haas-grotesk-text", Helvetica, sans-serif;

  --cl-black: #191919;
  --cl-white: #D3D4D6;
  --cl-dark-gray: #535154;
  --cl-orange: #e7572a;
  --cl-green: #A6C647;
  /* --cl-nuclear-green: #b3d26a; */
  --cl-nuclear-green: #A6C647;
}
/*
p h1 h2::selection {
  background: var(--cl-orange);
} */

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 0;
  background-color: var(--cl-black);
}

body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

/* RESPONSIVENESS */

@media screen and (min-width: 950px) {
  .hide {
    display: none;
  }
}

@media screen and (max-width: 949px) {
  .mx-hide {
    display: none;
  }

  .mx-flex-dir {
    flex-direction: column;
  }

  .abt-p-container {
    width: 100vw;
  }

  .about-p {
    max-width: none;
    margin-left: 3vw;
    margin-top: 3vw;
    margin-bottom: 6vw;
  }

  .about-mx-text {
    font-size: 5vw;
  }

  .mx-spotify-container {
    display: flex;
    margin: 3vw;
  }

  .mx-spotify {
    margin-top: 6vw;
  }
}
