.app__contact {
  width: 100vw;
  height: 100vh;

  background-color: var(--cl-black);
  color: var(--cl-white);

  display: flex;
  //   align-items: flex-end;
  flex-direction: column;
  justify-content: space-between;
}

.app__contact-header {
  width: 100vw;
  max-width: 100vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column; /* Stack items vertically on small screens */
  align-items: center; /* Center items horizontally on small screens */
  padding-left: 0.25rem;
  padding-right: 0.25rem;

  div {
    width: 100vw;
    display: flex;
    justify-content: space-between;
    padding-left: 1vw;
    padding-right: 1vw;
  }

  h1 {
    // font-family: var(--ff-display);
    font-style: normal;
    font-weight: 600;
    font-size: 10.85vw;
    line-height: 1;
    text-align: center;
    max-width: 100%;
  }

  h2 {
    font-family: var(--ff-display);
    font-style: normal;
    font-weight: 500;
    font-size: 3vw;
    line-height: 0.5;
  }
}

footer {
  width: 98vw;
  color: var(--cl-white);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  span {
    margin-bottom: 1vw;
    margin-left: 0.25vw;

    a {
      color: var(--cl-white);
      text-decoration: none;
    }
  }
}

.contact-links {
  margin-top: 12vw;
  display: flex;
  flex-direction: row;
  margin-left: 1vw;
}

.contact-link {
  display: flex;
  flex-direction: row;

  h3 a {
    text-decoration: none;
    color: var(--cl-white);
    font-size: 6vw;
    line-height: 100%;
    font-weight: 800;
    transition: 0.25s ease-in-out;

    &:hover {
      color: var(--cl-nuclear-green);
      cursor: pointer;
    }
  }

  p {
    margin-top: 0.5vw;
  }
}

#linkedin:hover {
  color: #0077b5;
}
#github:hover {
  color: #6e5494;
}
#dribbble:hover {
  color: #ea4c89;
}

.contact-column-2 {
  margin-left: 5vw;
}

.contact-p {
  max-width: 33vw;
  height: 15vw;
  margin-left: 1.5vw;

  font-size: 1.5vw;
  opacity: 0.5;
  font-weight: 200;
}

.spotify {
  margin: 3vw;
  margin-bottom: 0;
  margin-top: 0;
}

@media (max-width: 500px) {
  .contact-column-1 {
    margin-left: 2vw;
    margin-top: 20vw;
  }

  .contact-column-2 {
    margin-left: 2vw;
    margin-top: 5vw;
    margin-bottom: 0;
  }

  .contact-link {
    h3 a {
      text-decoration: none;
      color: var(--cl-white);
      font-size: 15vw;
      line-height: 100%;
      font-weight: 800;
      transition: 0.25s ease-in-out;
    }
  }

  .contact-p {
    font-size: 5vw;
    max-width: 100%;
    margin-left: 3vw;
    margin-top: 0;
    height: 40vw;
  }

  footer {
    width: 98vw;
    font-size: small;

    span {
      margin-bottom: 1vw;
      margin-left: 3vw;
    }
  }
}
